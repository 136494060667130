import { memo } from "react";

const BalanceCell = memo((props) => {
    const value = props.getValue();
    if (value === null || value === "-" || isNaN(value)) {
        return "0.0"
    }
    const formatter = new Intl.NumberFormat('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return formatter.format(value);
});

export default BalanceCell
