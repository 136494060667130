/* This code snippet is setting up an API configuration for making authenticated requests to a server
using Axios in a React application. Here's a breakdown of what the code is doing: */
// ! Here the difference between the apiConfig & apiConfigClientID is the ClientID is not passed in this configuration
import {
  IAuthTokens,
  getAccessToken,
  getRefreshToken,
  clearAuthTokens,
  setAuthTokens,
} from "axios-jwt";
import axios from "axios";
import ReactDOM from "react-dom";
import SnackbarMessage from "../components/Shared/SnackbarMessage/SnackbarMessage";

const BASE_URL =
  process.env.REACT_APP_ENV_NAME === "production"
    ? process.env.REACT_APP_BULK_ENDPOINT
    : process.env.REACT_APP_API_ENDPOINT;
if (!BASE_URL) throw new Error("BASE_URL is not defined");

export interface IAuthResponse {
  access_token: string;
  refresh_token: string;
}
export const AuthResponseToAuthTokens = (res: IAuthResponse): IAuthTokens => ({
  accessToken: res.access_token,
  refreshToken: res.refresh_token,
});
export let APIConfig = {
  BASE_URL: BASE_URL,
  API_Client: axios.create(),
};
APIConfig.API_Client.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
      // config.headers["client_id"] = JSON.parse(
      //   localStorage.getItem("user")
      // ).client_id;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
APIConfig.API_Client.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest.url === BASE_URL + "/v2/auth/token"
    ) {
      clearAuthTokens();
      window.location.reload();
      return Promise.reject(error);
    }
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 400) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      return axios
        .get(BASE_URL + "/v2/auth/token", {
          headers: { Authorization: "Bearer " + getRefreshToken() },
        })
        .then((response) => {
          if (response.status === 200) {
            response.data.refresh_token = getRefreshToken();
            setAuthTokens(AuthResponseToAuthTokens(response.data));
            return APIConfig.API_Client(originalRequest);
          }
        })
        .catch((error) => {
          clearAuthTokens();
          window.location.reload();
        });
    }
    return Promise.reject(error);
  }
);
export let APIConfigFormData = {
  BASE_URL: BASE_URL,
  API_Client: axios.create(),
};
APIConfigFormData.API_Client.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
      // config.headers["client_id"] = JSON.parse(
      //   localStorage.getItem("user")
      // ).client_id;
    }
    config.headers["Content-Type"] = "multipart/form-data";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
APIConfigFormData.API_Client.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest.url === BASE_URL + "/v2/auth/token"
    ) {
      clearAuthTokens();
      window.location.reload();
      return Promise.reject(error);
    }
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      return axios
        .get(BASE_URL + "/v2/auth/token", {
          headers: { Authorization: "Bearer " + getRefreshToken() },
        })
        .then((response) => {
          if (response.status === 200) {
            response.data.refresh_token = getRefreshToken();
            setAuthTokens(AuthResponseToAuthTokens(response.data));
            return APIConfigFormData.API_Client(originalRequest);
          }
        })
        .catch((error) => {
          clearAuthTokens();
          window.location.reload();
        });
    }
    return Promise.reject(error);
  }
);
